import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  formatDateTime,
  numberWithCommas,
  numberWithCommasExtra,
} from "../../utils/utils";
import { Pagination } from "antd";

function Commitment() {
  document.title = "Residences";
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [param, setParam] = useState({
    items: "10",
    offset: "0"
  });

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "commitment",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };


  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return (
    <div className="block p-4 bg-white rounded-md">
      <div className="flex justify-between">
        <p>Commitment</p>
      </div>
      <div className="flex items-center mt-4 pagination-container">
        <p>Show</p>
        <div className="flex items-center ">
          <Pagination
            onChange={paginate}
            defaultCurrent={1}
            pageSize={param.items}
            current={param.offset + 1}
            total={51}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Unit</th>
              <th>Amount</th>
              <th>Invested</th>
              <th>Status</th>
              <th>User</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Submitted on</th>
            </tr>
          </thead>

          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{d.name}</td>
                <td className="text-end">{numberWithCommas(d.amount)}</td>
                <td className="text-end">{numberWithCommas(d.invested)}</td>
                <td>{d.is_deleted === 1 && "Cancelled"}</td>
                <td>{d.first_name} {d.last_name}</td>
                <td>{d.phone_no}</td>
                <td>{d.email_id}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        onChange={paginate}
        defaultCurrent={1}
        pageSize={param.items}
        current={param.offset + 1}
        total={total}
      />
    </div>
  );
}

export default Commitment;
