import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/index";
import Residences from "../pages/residences/index";
import Accommodations from "../pages/accommodations";
import Users from "../pages/users";
import Investments from "../pages/investments";
import Tokens from "../pages/investments/tokens";
import BankPayments from "../pages/bankPay";
import Commitment from "../pages/commitment";

function Content() {
  return (
    <div className="w-full h-full px-6 py-4 bg-gray-200">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/residence" element={<Residences />} />
        <Route path="/units" element={<Accommodations />} />
        <Route path="/users" element={<Users />} />
        <Route path="/investments" element={<Investments />} />
        <Route path="/commitment" element={<Commitment />} />
        <Route path="/bank-payment" element={<BankPayments />} />
        <Route path="/tokens" element={<Tokens />} />
      </Routes>
    </div>
  );
}

export default Content;
