import React, { useState } from "react";
import { DashboardOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineHomeWork } from "react-icons/md";
import { LiaHotelSolid } from "react-icons/lia";
import { RiPassPendingLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { FaCoins } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { BsBank } from "react-icons/bs";
import { BsCart3 } from "react-icons/bs";


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Dashboard", "", <DashboardOutlined />),
  getItem("Users", "users", <FiUsers />),
  getItem("Units", "units", <LiaHotelSolid />),
  getItem("Investments", "investments", <GiReceiveMoney />),
  getItem("Bank Payments", "bank-payment", <BsBank />),
  getItem("Commitment", "commitment", <RiPassPendingLine />),
  getItem("PIE Tokens", "tokens", <FaCoins />),
  getItem("Residences", "residence", <MdOutlineHomeWork />),
  getItem("Marketplace", "https://app.blocksquare.io/admin/login", <BsCart3 />),
  // getItem("Navigation One", "sub1", <MailOutlined />, [
  //   getItem("Option 1", "1"),
  //   getItem("Option 2", "2"),
  //   getItem("Option 3", "3"),
  //   getItem("Option 4", "4"),
  // ]),
  // getItem("Navigation Two", "sub2", <AppstoreOutlined />, [
  //   getItem("Option 5", "5"),
  //   getItem("Option 6", "6"),
  //   getItem("Submenu", "sub3", null, [
  //     getItem("Option 7", "7"),
  //     getItem("Option 8", "8"),
  //   ]),
  // ]),
  // getItem("Navigation Three", "sub4", <SettingOutlined />, [
  //   getItem("Option 9", "9"),
  //   getItem("Option 10", "10"),
  //   getItem("Option 11", "11"),
  //   getItem("Option 12", "12"),
  // ]),
];

// submenu keys of first level
function SideBar() {
  let location = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <div className="h-screen overflow-y-auto side-bg min-w-64">
      <div className="p-4 text-center text-white">
        <p>Admin</p>
      </div>
      <Menu
        mode="inline"
        theme="dark"
        openKeys={openKeys}
        defaultSelectedKeys={[location.pathname.substring(1)]}
        onOpenChange={onOpenChange}
        onClick={(e) => {
          if (e.key === "https://app.blocksquare.io/admin/login")
            window.location.href = "https://app.blocksquare.io/admin/login"
          else
            navigate(e.key)
        }}
        style={{
          width: 256,
        }}
        items={items}
      />
    </div>
  );
}

export default SideBar;
